import BarChartIcon from "@material-ui/icons/BarChart"
import CalendarTodayIcon from "@material-ui/icons/CalendarToday"
import ChatIcon from "@material-ui/icons/ChatOutlined"
import CodeIcon from "@material-ui/icons/Code"
import DashboardIcon from "@material-ui/icons/DashboardOutlined"
import ErrorIcon from "@material-ui/icons/ErrorOutline"
import FolderIcon from "@material-ui/icons/FolderOutlined"
import DashboardTwoToneIcon from "@material-ui/icons/DashboardTwoTone"
import GradeTwoTone from "@material-ui/icons/GradeTwoTone"
import ListAltIcon from "@material-ui/icons/ListAlt"
import LockOpenIcon from "@material-ui/icons/LockOpenOutlined"
import MailIcon from "@material-ui/icons/MailOutlined"
import PresentToAllIcon from "@material-ui/icons/PresentToAll"
import PeopleIcon from "@material-ui/icons/PeopleOutlined"
import PersonIcon from "@material-ui/icons/PersonOutlined"
import ReceiptIcon from "@material-ui/icons/ReceiptOutlined"
import SettingsIcon from "@material-ui/icons/SettingsOutlined"
import ViewModuleIcon from "@material-ui/icons/ViewModule"
import AttachFile from "@material-ui/icons/AttachFile"
import Scanner from "@material-ui/icons/FileCopy"
import Contact from "@material-ui/icons/ContactMail"

var iconsMap = {
  BarChartIcon: BarChartIcon,
  CalendarTodayIcon: CalendarTodayIcon,
  ChatIcon: ChatIcon,
  CodeIcon: CodeIcon,
  DashboardIcon: DashboardIcon,
  ErrorIcon: ErrorIcon,
  FolderIcon: FolderIcon,
  DashboardTwoToneIcon: DashboardTwoToneIcon,
  GradeTwoTone: GradeTwoTone,
  ListAltIcon: ListAltIcon,
  LockOpenIcon: LockOpenIcon,
  MailIcon: MailIcon,
  PresentToAllIcon: PresentToAllIcon,
  PeopleIcon: PeopleIcon,
  PersonIcon: PersonIcon,
  ReceiptIcon: ReceiptIcon,
  SettingsIcon: SettingsIcon,
  ViewModuleIcon: ViewModuleIcon,
  AttachFile: AttachFile,
  Scanner: Scanner,
  Contact: Contact
}

export default [
  {
    label: "Navigation menu",
    content: JSON.parse(
      `[
  {
    "label": "SETT",
    "icon": "DashboardTwoToneIcon",
    "content": [
      {
        "label": "Home",
        "description": "",
        "to": "/"
      }
    ]
  },
  {
    "label": "Projects",
    "icon": "GradeTwoTone",
    "content": [
      {
        "label": "Eritrean Govt in Exile",
        "description": "",
        "to": "/eritrean_gov_exile"
      }
    ]
  },
  {
    "label": "View and Review",
    "icon": "AttachFile",
    "to": "/articles"
  },
  
  {
    "label": "Documents",
    "icon": "Scanner",
    "content": [
      {
        "label": "Current Document",
        "description": "",
        "to": "/current_documents"
      },
      {
        "label": "Archive",
        "description": "",
        "to": "/documents"
      },
      {
        "label": "Articles",
        "description": "",
        "to": "/articles_by_set"
      },
      {
        "label": "Upload Document",
        "description": "",
        "to": "/documents/upload"
      }
    ]
  },
  {
    "label": "Links",
    "icon": "CodeIcon",
    "to": "/links"
  },
  {
    "label": "Contact US",
    "icon": "Contact",
    "to": "/contact_us"
  }
]`,
      (key, value) => {
        if (key === "icon") {
          return iconsMap[value]
        } else {
          return value
        }
      }
    )
  }
]

// {
//   "label": "View and Review",
//   "icon": "AttachFile",
//   "content": [
//     {
//       "label": "All",
//       "description": "",
//       "to": "/articles"
//     },
//     {
//       "label": "Current Articles By Set",
//       "description": "",
//       "to": "articles_by_set"
//     }
//   ]
// },
