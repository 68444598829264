import React, { lazy, Suspense, Fragment } from "react"
import { Switch, Route, useLocation } from "react-router-dom"
import { AnimatePresence, motion } from "framer-motion"

import { ThemeProvider } from "@material-ui/styles"

import { ClimbingBoxLoader } from "react-spinners"

import MuiTheme from "./theme"
import LeftSidebar from "components/LeftSidebar"
import MinimalLayout from "components/MinimalLayout"
import Login from "pages/Login"
import Register from "pages/Register"
import EritreaToday from "pages/EritreaToday"
import GotInExile from "pages/GotInExile"
import CommissionForJustice from "pages/CommissionForJustice"
import RapidEmergencyUnit from "pages/RapidEmergencyUnit"
import Coordinators from "pages/Coordinators"
import Representatives from "pages/Representatives"
import EritreanGovExile from "pages/EritreanGovExile"
import CurrentDocuments from "pages/CurrentDocuments"
import UploadDocument from "pages/UploadDocument"
// import Experts from "pages/Experts"
// import Consultants from "pages/Consultants"

const ArticlesBySet = lazy(() => import("pages/ArticlesBySet"))
const DocumentsEritrea = lazy(() => import("pages/DocumentsEritrea"))
const DocumentsInternational = lazy(() =>
  import("pages/DocumentsInternational")
)
const DocumentsCustomaryLaws = lazy(() =>
  import("pages/DocumentsCustomaryLaws")
)
const ContactUs = lazy(() => import("pages/ContactUs"))
const Articles = lazy(() => import("pages/Articles"))
const Documents = lazy(() => import("pages/Documents"))
const Home = lazy(() => import("pages/Home"))

// Example Pages

// import Buttons from './example-pages/Buttons';
// import RegularTables1 from './example-pages/RegularTables1';

// const LandingPage = lazy(() => import('./example-pages/LandingPage'));

const Routes = () => {
  const location = useLocation()

  const pageVariants = {
    initial: {
      opacity: 0,
      scale: 0.99
    },
    in: {
      opacity: 1,
      scale: 1
    },
    out: {
      opacity: 0,
      scale: 1.01
    }
  }

  const pageTransition = {
    type: "tween",
    ease: "anticipate",
    duration: 0.4
  }

  const SuspenseLoading = () => {
    return (
      <Fragment>
        <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
          <div className="d-flex align-items-center flex-column px-4">
            <ClimbingBoxLoader color={"#5383ff"} loading={true} />
          </div>
          <div className="text-muted font-size-xl text-center pt-3">
            Please wait while we load the page
            <span className="font-size-lg d-block text-dark">loading...</span>
          </div>
        </div>
      </Fragment>
    )
  }
  return (
    <ThemeProvider theme={MuiTheme}>
      <AnimatePresence>
        <Suspense fallback={<SuspenseLoading />}>
          <Switch>
            <Route path={["/login", "/register"]}>
              <MinimalLayout>
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}
                  >
                    <Route path="/login" component={Login} />
                    <Route path="/register" component={Register} />
                  </motion.div>
                </Switch>
              </MinimalLayout>
            </Route>
            <Route path={["/"]}>
              <LeftSidebar>
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}
                  >
                    <Route exact={true} path="/" component={Home} />
                    <Route path="/contact_us" component={ContactUs} />
                    <Route path="/articles" component={Articles} />
                    <Route path="/articles_by_set" component={ArticlesBySet} />
                    <Route
                      exact={true}
                      path="/documents"
                      component={Documents}
                    />
                    <Route
                      path="/eritrean_documents"
                      component={DocumentsEritrea}
                    />
                    <Route
                      path="/international_documents"
                      component={DocumentsInternational}
                    />
                    <Route
                      path="/customary_documents"
                      component={DocumentsCustomaryLaws}
                    />
                    <Route path="/links" component={EritreaToday} />
                    <Route path="/got_in_exile" component={GotInExile} />
                    <Route
                      path="/commission_for_justice"
                      component={CommissionForJustice}
                    />
                    <Route
                      path="/rapid_emergency_unit"
                      component={RapidEmergencyUnit}
                    />
                    <Route path="/coordinators" component={Coordinators} />
                    {/* <Route path="/consultants" component={Consultants} /> */}
                    <Route
                      path="/representatives"
                      component={Representatives}
                    />
                    <Route
                      path="/eritrean_gov_exile"
                      component={EritreanGovExile}
                    />
                    <Route
                      path="/current_documents"
                      component={CurrentDocuments}
                    />
                    <Route
                      path="/documents/upload"
                      component={UploadDocument}
                    />
                  </motion.div>
                </Switch>
              </LeftSidebar>
            </Route>
          </Switch>
        </Suspense>
      </AnimatePresence>
    </ThemeProvider>
  )
}

export default Routes
