import React, { Fragment } from "react"

import clsx from "clsx"
import { Link } from "react-router-dom"

import { Hidden, IconButton, AppBar, Box, Tooltip } from "@material-ui/core"

import { connect } from "react-redux"

import {
  setSidebarToggle,
  setSidebarToggleMobile
} from "../../reducers/ThemeOptions"
import projectLogo from "../../assets/images/logo_circle.png"

import HeaderLogo from "./HeaderLogo"
import HeaderDots from "./HeaderDots"
import HeaderDrawer from "./HeaderDrawer"
import HeaderUserbox from "./HeaderUserbox"
import HeaderSearch from "./HeaderSearch"
import HeaderMenu from "./HeaderMenu"

import MenuOpenRoundedIcon from "@material-ui/icons/MenuOpenRounded"
import MenuRoundedIcon from "@material-ui/icons/MenuRounded"

const Header = props => {
  const toggleSidebar = () => {
    setSidebarToggle(!sidebarToggle)
  }

  const toggleSidebarMobile = () => {
    setSidebarToggleMobile(!sidebarToggleMobile)
  }
  const {
    headerShadow,
    headerFixed,
    sidebarToggleMobile,
    setSidebarToggleMobile,
    setSidebarToggle,
    sidebarToggle
  } = props

  return (
    <Fragment>
      <AppBar
        color="secondary"
        className={clsx("app-header", {
          "app-header-collapsed-sidebar": props.isCollapsedLayout
        })}
        position={headerFixed ? "fixed" : "absolute"}
        elevation={headerShadow ? 11 : 3}
      >
        {!props.isCollapsedLayout && <HeaderLogo />}
        <Box className="app-header-toolbar">
          <Hidden lgUp>
            <Box
              className="app-logo-wrapper"
              title="Society of Eritrean Think Tank"
            >
              <Link to="/" className="app-logo-link">
                <IconButton
                  color="primary"
                  size="large"
                  className="bg-white rounded-full p-0 overflow-hidden w-14 h-14"
                >
                  <img
                    className="w-16 h-16 scale-120"
                    alt="Society of Eritrean Think Tank"
                    src={projectLogo}
                  />
                </IconButton>
              </Link>
              <Hidden smDown>
                <Box className="app-logo-text">SETT</Box>
              </Hidden>
            </Box>
          </Hidden>
          <Hidden mdDown>
            <Box className="d-flex align-items-center">
              {!props.isCollapsedLayout && (
                <Box
                  className={clsx("btn-toggle-collapse", {
                    "btn-toggle-collapse-closed": sidebarToggle
                  })}
                >
                  <Tooltip title="Toggle Sidebar" placement="right">
                    <IconButton
                      color="inherit"
                      onClick={toggleSidebar}
                      size="medium"
                      className="btn-inverse"
                    >
                      {sidebarToggle ? (
                        <MenuRoundedIcon />
                      ) : (
                        <MenuOpenRoundedIcon />
                      )}
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
              {/* <HeaderSearch /> */}
              {/* <HeaderMenu /> */}
            </Box>
          </Hidden>
          <Box className="d-flex align-items-center">
            {/* <HeaderDots /> */}
            <HeaderUserbox />
            {/* <HeaderDrawer /> */}
            <Box className="toggle-sidebar-btn-mobile">
              <Tooltip title="Toggle Sidebar" placement="right">
                <IconButton
                  color="inherit"
                  onClick={toggleSidebarMobile}
                  size="medium"
                >
                  {sidebarToggleMobile ? (
                    <MenuOpenRoundedIcon />
                  ) : (
                    <MenuRoundedIcon />
                  )}
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </Box>
      </AppBar>
    </Fragment>
  )
}

const mapStateToProps = state => ({
  headerShadow: state.ThemeOptions.headerShadow,
  headerFixed: state.ThemeOptions.headerFixed,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
  sidebarToggle: state.ThemeOptions.sidebarToggle
})

const mapDispatchToProps = dispatch => ({
  setSidebarToggle: enable => dispatch(setSidebarToggle(enable)),
  setSidebarToggleMobile: enable => dispatch(setSidebarToggleMobile(enable))
})

export default connect(mapStateToProps, mapDispatchToProps)(Header)
