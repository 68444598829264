import React, { Fragment } from "react"

export default function CommissionForJustice() {
  return (
    <Fragment>
      <div>
        <h1 className="display-4 my-3 font-weight-bold">
          Eritrean Commission for Justice – ECJ
        </h1>
        <div>
          <p className="text-justify">
            Eritrean commission for Justice – ECJ – entails the whole process of
            transitional justice in full meaning covering the reconciliation
            process and compensating the victims. However, preliminary report
            awaits to be published with full description and defining the
            meaning of the law applicable to this process and the extent of
            power exercise; the role of the commissioners as to how to proceed
            and how far back historic abuses would be traced and delineated;
            define the power and remit of the Commission awaits how this process
            understood within the framework and context of Eritrean political
            activities and the cause risen for truth, justice, reconciliation
            and ultimately compensating the victims. The statutory proceeding
            would be determined by the assembly and becomes applicable law.
          </p>
        </div>
      </div>
    </Fragment>
  )
}
