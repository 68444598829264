import React, { Fragment } from "react"

export default function Coordinators() {
  return (
    <Fragment>
      <div>
        <h1 className="display-4 my-3 font-weight-bold">Coordinators</h1>
        <div>
          <div class="entry-content clearfix">
            <p>
              <strong>Coordinators</strong>
            </p>

            <p>
              Elected member of the Baito or Assembly for the Government in
              exile who represent the EGiE hold plenary session in person or be
              called upon their meeting via the zoom meeting.&nbsp; With overall
              fair representation of the political parties taking into
              consideration of their political views and stand on their
              commitment for the sovereign states of Eritrea and democratic
              values and as well as representatives of the nine regional states
              or Werejatat of Eritrea.{" "}
            </p>

            <p>
              <strong>Who’s who</strong>
            </p>

            <p>
              {" "}
              Draw&nbsp; a map or infographic of the who’s who in the EGiE:{" "}
            </p>

            <p> Baito or Assembly </p>

            <p> The President of the EGiE Vice-presidents </p>

            <p> Exchequers </p>

            <p> Roles and how the election is held to follow:</p>
          </div>
        </div>
      </div>
    </Fragment>
  )
}
