import React, { Fragment } from "react"

export default function EritreaToday() {
  return (
    <Fragment>
      <div className="custom_background">
        <h1 className="display-4 my-3 font-weight-bold">Eritrea Today</h1>
        <div>
          <div className="flex flex-wrap">
            <a
              className="flex m-2 w-full md:w-1/3 lg:w-1/5 px-4 py-6 shadow-xl hover:shadow-2xl active:shadow-xl bg-white h-16"
              href="https://snitna.com/"
              target="_blank"
              rel="noreferrer"
            >
              Snitna
            </a>
            {/* <a
              className="flex m-2 w-full md:w-1/3 lg:w-1/5 px-4 py-6 shadow-xl hover:shadow-2xl active:shadow-xl bg-white h-16"
              href="https://awate.com/"
              target="_blank"
              rel="noreferrer"
            >
              Awate
            </a>
            <a
              className="flex m-2 w-full md:w-1/3 lg:w-1/5 px-4 py-6 shadow-xl hover:shadow-2xl active:shadow-xl bg-white h-16"
              href="https://asmarino.com/"
              target="_blank"
              rel="noreferrer"
            >
              Asmarino
            </a>
            <a
              className="flex m-2 w-full md:w-1/3 lg:w-1/5 px-4 py-6 shadow-xl hover:shadow-2xl active:shadow-xl bg-white h-16"
              href="https://asenatv.com/"
              target="_blank"
              rel="noreferrer"
            >
              Assenna
            </a> */}
            <a
              className="flex m-2 w-full md:w-1/3 lg:w-1/5 px-4 py-6 shadow-xl hover:shadow-2xl active:shadow-xl bg-white h-16"
              href="https://erena.org/"
              target="_blank"
              rel="noreferrer"
            >
              RadioErena
            </a>
          </div>
        </div>
      </div>
    </Fragment>
  )
}
