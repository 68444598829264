import React, { Fragment } from "react"
import { Link } from "react-router-dom"
import { Grid, Container } from "@material-ui/core"

export default function GotInExile() {
  return (
    <Fragment>
      <div>
        <h1 className="display-4 my-3 font-weight-bold">
          Eritrean government in exile - EGIE
        </h1>

        <div className="flex items-center mt-4 mb-4">
          <span className="inline-block mr-4">
            The Path to Eritrean Govt in Exile
          </span>
          <Link
            className="flex items-center text-white bg-primary py-2 px-4 font-extrabold text-lg "
            to="/pdf/Organization-of-the-Diaspora-for-Govt-for-Exile.pdf"
            target="_blank"
            style={{ borderRadius: "5rem" }}
          >
            <span className="inline-block">View</span>
          </Link>
        </div>

        <Container className="py-3 py-xl-5">
          <Grid container spacing={4}>
            <Grid item xs={12} lg={6}>
              <h2 className="text-xl my-3 font-weight-bold">
                Eritrean government in exile - EGIE
              </h2>
              <div class="wp-block-column">
                <p className="text-justify">
                  Eritrean government in exile necessitated after three decades
                  of an ad-hoc provisional government run by PFDJ ruling party
                  who wasted human and material resources waging multiple wars
                  in five different countries. More so, the state of Eritrea
                  breaches many of the UN charter Conventions and treaties.
                  Internal affair functionaries deprived of constitution,
                  parliamentary system, and all other norms of state
                  institutions, implicated by UN recognized recorded Human Right
                  abuses under its belt and the recent 2020/1 Tigray-Ethiopia
                  &nbsp;invasion committing genocide is under watchful eye by
                  the International community.
                </p>

                <p className="text-justify">
                  In light of the decline and fall of state of Eritrea’s current
                  position the formation of credible and serious body of
                  Eritrean government in exile necessitated to replace the
                  incumbent government in situ who lacks respectable and
                  responsible &nbsp;governmental institutions and basic rule of
                  law. Hence, the EGiE will endeavor to prepare and strengthen
                  multi-faceted democratization process in view of eventual
                  replacing the incumbent government. It is much expected the
                  international community will recognize the Eritrea government
                  in exile as a legitimate representative of Eritrean people
                  within and in exile working in democratization process. It is
                  anticipated in accordance&nbsp; to the international law being
                  the case of the matter.
                </p>

                <p className="text-justify">
                  The recognized Eritrean government in Exile would be assuming
                  and undertake as much functions permitting its’ capacities and
                  limitations in the conduct of its day to day affairs. Such
                  conducts will cover the following roles:
                </p>

                <ul className="list-disc">
                  <li className="mb-2">
                    Holding&nbsp;democratic elections; the EGiE will be run by
                    an assembly representative of all Eritreans with a backup
                    office working full time.
                  </li>
                  <li className="mb-2">
                    Preparing democratisation process and documentations in all
                    aspect of Eritrea functions and infrastructure: Health,
                    Welfare, Business and Industry, State pension, Education,
                    Transport, Defence (land, Air and Sea), Public order and
                    Safety, Government Administration, Housing, Environment, and
                    Culture (Sport, Libraries, museum)
                  </li>
                  <li className="mb-2">
                    Securing and maintaining diplomatic recognition&nbsp;from
                    other states
                  </li>
                  <li className="mb-2">
                    Becoming a party to a bilateral or international&nbsp;treaty
                  </li>
                  <li className="mb-2">
                    Amending or revising Eritrea’s constitution
                  </li>
                  <li className="mb-2">Maintaining military force&nbsp;</li>
                  <li className="mb-2">Issuing Eritrean identity cards</li>
                  <li className="mb-2">
                    Vetting and endorsing political parties in line with an
                    approved guideline
                  </li>
                </ul>
              </div>
            </Grid>
            <Grid item xs={12} lg={6}>
              <h2 className="text-xl my-3 font-weight-bold">
                መንግስቲ ኤርትራ ኣብ ስደት (መ. ኤ. ኣ. ስ)
              </h2>
              <div class="wp-block-column">
                <p className="text-justify">መቕድም</p>

                <p className="text-justify">
                  ኣብ ኣጋ መፈጸምታ 2002 ዓ.ም.ፈ ሃገራት ኢትዮጵያ፡ ሱዳን ከምኡ’ውን የመን፡ ንኤርትራውያን
                  ተቛወምቲ ውድባት ብደረጃ መንግስቲ ኣብ ስደት ከም ዝሕግዝዎም ኣበሰሩ። ብሸነኾም ተቛወምቲ ሓይልታት
                  ዳርጋ ክልተ ኣዋርሕ ዝወሰደ ኣኼባ ኣብ ኣዲስ ኣበባ ድሕሪ ምስልሳል፡ ኣብ Eritrean
                  National Alliance ዝተባህል ጽላል ተጠርነፉ። ድሕሪኡ ዝተጠቕሱ ሃገራት ኣብ ከመዘልማድ
                  ሰንዓ ፎሩም ተባሂሉ ዝጽዋዕ ስምምዕ ተፈራረሙ። ከም ደምዳሚ ስጒምቲ ድማ ጥርኑፋት ተቓወምቲ
                  ውድባትን ሰንዓ ፎርምን ኣብ ግብራዊ ስራሕ ኣተዉ። እንተዀነ ግን ከም’ቲ ዝድለ ኣይተተግበረን።
                </p>

                <p className="text-justify">
                  ብዙሕ እዋን ከይሓለፈ ሃገራት ሕልፍ ሰንዓ ንመራሕቲ National Alliance ኣብ ካሩቱም
                  ዓደሙ። እወ፡ መራሕቲ ሰንዓ ፎሩም ንኣካየድቲ ተቓውሞ ኤርትራ ኣብ ግቢ ፕረዚደንት በሺር ንምይይጥን
                  ድራርን ጋበዙ፡ ደረጃ እቲ ግብዣ መንግስታዊ ነቢሩ ክብሃል ይከኣል። ንጽብሒቱ መራሕቲ Alliance
                  ነቲ ካብ ሕቕፉ ዝሰሓበ ሓይልን ተቓወምቲ ህግደፍን ኣብ ኣኼባ ብምጽዋዕ ስምረት ኣወጁ።
                </p>

                <p className="text-justify">
                  ዝተተቕሱ ተቓወምቲ ሓይልታት ኪዳን ኢሎም ዝሰመይዎ ፅላል ፈጠሩ። እቲ ጽዋይ ብዝተማልአ ገጹ ክሳብ
                  ዝዝንቶ፡ ሓደ ረቛሒ ምጥቃስ የድሊ። ንሱ ኸኣ ኣብ መኽተሚ ጉባኤ ኣዝዩ ላዕለዋይ ስልጣን ዝጾረ
                  ሚኒስተር መንግስቲ ሱዳን ዘመልከቶ መጥንቀቕታ እዩ፥ ”ንሕና መኻዚኖታት ኣጽዋርን ግምጃ ሃገረ ሱዳን
                  ክንከፍተልኩም ኢና፡ ኣብ ውሽጢ ክልተ ዓመታት ቃልስኹም ኣወንታዊ ውጽኢት ምስ ዘየርኢ ግን ሓገዝና
                  ከቋረጽ እዩ በለ”። ከም ወሰኽ፥ “እንተዀነ ግን ሱዳን ሃገርኩም ስለ ዝዀነት ከም ተራ ዜጋታት
                  ክትቅመጡ ሓራ ኢኹም” ብምባል ዘረብኡ ዓጸወ። ድሕሪ ክልተ ዓመታት ሰንዓ ፎሩም ምእንቲ ርእሱ
                  ላሸወ፡ ጒዕዞ ተቓውሞ ድማ ኮርኲሐ። ብሰንኪ ኣነ/ናተይ ዝብል ዕውር ኣነነትን ኣብ ውሽጢ ውድባት
                  ለመም ዝብሉ ኣትማንን ሃሱሳትን፡ እቲ ኣንኮ ዝዀነ ፍታሕ መንግስቲ ኣብ ስደት ተዓንቀፈ። &nbsp;
                </p>

                <p className="text-justify">ስኽፍታታት ኤርትራውያን ኣብ ስደት</p>

                <p className="text-justify">
                  ንራእይ መንግስቲ ኣብ ስደት ካብ ስኽፍታ ተበጊሶም ዝነጽጉ ኣለዉ፣ ገለ ካብዞም ስኽፍታታት ነዞም
                  ኣብ ታሕቲ ተገሊጾም ዘለዉ ርእይቶታት ይመስሉ፥
                </p>

                <ul>
                  <li>መንግስትታት ዓለም ክፈልጡልና ድዮም ፧</li>
                  <li>ገለ (ወላ ሓንቲ) ትድግፈና ሃገር ኣላዶ፧</li>
                  <li>ኣብያተ ጽሕፈት ክንከፍት ይፈቕዱልና ድዮም፧</li>
                  <li>ሓገዝ ገንዘብን ዲፕሎማስን ይውፍዩልና ድዮም፧</li>
                  <li>መንግስቲ ከሎ’ዶ መንግስቲ ይትከል እዩ፧ ዝብሉ እውን ኣለዉ።</li>
                </ul>

                <p className="text-justify">
                  ብሰንኪ ወሓዚ ስደት ኤርትራውያንን መወዳእታ ዘይብሉ ውሽጣውን ደገኣውን ኲናት፡ ሃገራት ዓለም ኣብ
                  ኤርትራ መንግስቲ ከም ዘየሎ ስለ ዝፈልጡ፡ ወትሩ ንኤርትራ ይቐጽዑን የዋስኑን። እንተ ብዛዕባ
                  ስግኣት ሓድሕድ ኲናት ግን መ. ኤ. ኣብ ስ. እምነ ኩርናዕ ሰላም እዩ።
                </p>

                <p className="text-justify">
                  እዞም ሕቶታት ካብ ተሞክሮ ሰንዓ ፎሩም ዝበቖሉ እዮም። እዚ ተሞክሮ ተመሊሱ ኣይቅልቀልን እዩ፡
                  ይትረፍ’ዶ ተሞክሮ ሰንዓ ፎሩም፡ እቲ ተራ ርክብ ኣብ መንጎ ሕውሓትን ተቛወምቲ ውድባትን ዝነበረ
                  ባይታኳ ደሪቑ ዘሎ። ነቲ ቀዳማይ ከም ብጥርሑ ዝተሓሸሸ ተሞክሮ ቖጺርና ሕጂ ግን ዘዋጽኣና ደኣ
                  ንግበር።
                </p>

                <p className="text-justify">
                  መንግስቲ ኣብ ስደት ዓለምለኻዊ ተፈላጥነትን ሕጋውነትን ዝለብስ ኣብ ባይታ ምስ ቀወመ እምበር
                  ቅድሚኡ ኣይኰነን።
                </p>

                <p className="text-justify">
                  መንግስቲ ኣብ ስደት ዓለመለኻዊ ሕጋውነት ዝጉንጸፍ ብደሞክራስያዊ ኣገባብ ዝተመርጸ መሪሕነት ምስ
                  ቀወመ እዩ። ቅድሚኡ ደአ እንታይ ስለ ዝኾነ’ዩ ሕጋዊ ኣፍልጦ ክሓትት፧
                </p>

                <p className="text-justify">
                  ንመስርሕ መንግስቲ ኣብ ስደት ካብ ጽንሰ-ሓሰብ ጀሚርካ ክሳብ ትኽለት እግሩን ኣብ ሕቚፊ ዲጊታል
                  ተክኖሎጊን ህያባቱን ይምስረት።&nbsp;
                </p>

                <p className="text-justify">
                  መንግስቲ ኣብ ስደት ብደሞክራስያዊ ኣገባብ ኣንተ ቖይሙ፡ ፖለቲካዊ ተዓዘብትን ውሩያት ወከልቲ
                  ሚድያን ወዘተ ንዓይኒ ምስክርነት ምስ ዝዕደሙ እሞ ነቲ ዝቐውም መንግስቲ ብደሞክራስን ብቑዓት
                  ኤርትራውያን ምስ ዝምራሕ ንዝገጥምዎ ጸገማት ክንብድሆም ይኽእል።
                </p>

                <p className="text-justify">
                  ህዝቢ ኤርትራ ብሃገራዊ ጉባኤ ይጠርነፍ ዝብሉ እውን ኣለዉ። ግን ሃገራዊ ጉባኤ ክልተ ሻብ ኣማሊኡ
                  ይቃለስን፡ የቃልስን ስለ ዘሎ፡ ሃየ ኩላትና ኣብ ሕዛም መንግስቲ ኣብ ስደት ተዓጢቕና ሃገርና
                  ነድሕን።
                </p>

                <p className="text-justify">ኣድላይነት መንግስቲ ኣብ ስደት</p>

                <p className="text-justify">
                  ቀንዲ ኣድላይነት መንግስቲ ኣብ ስደት ንህዝቢ ኤርትራ ካብ ጥፍኣት ምድሓን እዩ። ክሳብ ሕጂ ነዚ
                  ማእከላዊ ዕላማ ክትግብር ዝኽእል መሪሕነት ክህነጽ ኣይተኻእለን። ኣብ ግዜ ቃልሲ ነጻ ዝወጽኤ መሬት
                  ዝበሃል ዝነበረ፡ ድሕሪ ነጻነት ኩላ ኤርትራ ከም ነጻ ዝወጸት መሬት እምበር ከም ሃገር ኣይቆመትን።
                  መንእሰያትና ምእረራ ውሽጣዊ ህዋሳቶም ብምህዳን ከም ኣጣል ይሽየጡ ወይ ባሕሪ ይውሕጦም፣ እቶም
                  ዝተረፉ ድማ ይመኽኑ። ደቂ ሃገር፡ እቲ ዝብሃል ተባሂሉ እዩ፡ እቲ ዝብከ ተንጀብጂቡ እዩ፡ ረኣዩንዶ
                  ዘረባ እኳ ተሓንኲኹ።
                </p>

                <p className="text-justify">
                  እምብኣር ብመንገዲ መንግስቲ ኣብ ስደት ንህዝብን ሃገርን ካብ ጥፍኣት ንምድሓን፡
                </p>

                <ul>
                  <li>ሓደ ብደምክራስያዊ ኣገባብ ዝተመርጸ መሪሕነት ይቐውም</li>
                  <li>ሓደ ፖለቲካዊ ድምጺ ይውነን</li>
                  <li>ሓደ ዲፕሎምስያዊ መርበብ ይዝርጋሕ</li>
                  <li>ሓደ ሃገራዊ ግምጃ ይትከል</li>
                  <li>ሓደ ስትራተጊን ፖሊሲን ይሕንጸጽ</li>
                </ul>

                <p className="text-justify">
                  ብዘይ ጥርጥር ኣብ ድያስፖራ ዝርከብ ህዝብና ሰፊሕ ስለ ዝዀነ፡ መንግስቲ ኣብ ስደት ምስ ዝጥርነፍ፡
                  ኣብ ዓለም ሻቡ ከም ሕጋዊ ኣማራጺ ይፍለጥ፡ ኣቋውምኡ ደሞክርስያዊ ምስ ዝኸውን ድማ፡ ከም ኣንኮ
                  ወኪል ህዝቢ ኤርትራ ንነፍሱ ክቕርብ ይኽእል።&nbsp;
                </p>

                <p className="text-justify">
                  መንግስቲ ኣብ ስደት ስለ ኤርትራውያን ስደተንያታት ኣብ ሕቡራት መንግስትታትን ኢመንግስታዊ ትካላትን
                  ክጣበቕ ይኽእል ጥራይ ዘይኮነ፡ ምስ ዝምልከቶም ሃገራት ብምትሕብባር ንውሕዘት ኤርትራዊ ስደት
                  ንምግታእ ግዲኡ ክጻወት ይኽእል።
                </p>

                <p className="text-justify">
                  መንግስቲ ኣብ ስደት ንኤርትራ ኣብ ዝምልከቱ ድርድራት ተዋሳኢ ክኸውን ይኽእል።
                </p>

                <p className="text-justify">
                  መንግስቲ ኣብ ስደት ነቲ ኣብ መንጎ ኤርትራን ትግራይን ተኸሲቱ ዘሎ መሪር ኲናት፡ ብሰላም ክድምድም
                  ይኽእል።
                </p>

                <p className="text-justify">
                  መንግስቲ ኣብ ስደት ፖለቲካውን ሰላማውን ስለዝዀነ፡ ኣብ ውሽጢ ሃገር ህዝባዊ ምንቅስቓስ ከበራብር
                  ይኽእል።
                </p>

                <p className="text-justify">
                  ዲክታተራዊ ስርዓት ብሃንደበት ምስ ዝውድቕ ክሳብ ምርጻ ዝካየድ ፤መንግስቲ ኣብ ስደት ሃገር
                  የረጋግእ።
                </p>

                <p className="text-justify">
                  መራሕቲ መንግስቲ ኣብ ስደት ምስ ኣብ ኤርትራ ኩባንያታት ዝውንኑ ሃገራት ክመያየጡ መሰል ኣሎዎም።
                </p>

                <p className="text-justify">
                  መራሕቲ መንግስቲ ኣብ ስደት ንዜጋታቶምን ሃገሮምን ኣብ ዓለምለኻዊ ኣብያተ ፍርዲ ክውክሉ መሰል
                  ኣለዎም።
                </p>

                <p className="text-justify">ኣሰራርዓ መንግስቲ ኣብ ስደት</p>

                <p className="text-justify">
                  መንግስቲ ኣብ ስደት ከመይ እዩ ዝስራዕ፧ ነዚ ዝበቕዑ ኣለውና ድዮም፧ ኢሎም ዘታርፉ ቀቢጸ-ተስፋታት
                  ቅልቅል እንተበሉ ዘይምጕሃይ፡ ንቕድሚት ምውንጻፍ ደኣ እምበር።
                </p>

                <p className="text-justify">
                  መንግስቲ ኣብ ስደት ክልተ ዝምባሌታት ኣለውዎ፣ እቲ ሓደ ዝምባለ ካብ ውጽኢት ጉባኤታት ተጋድሎ
                  ሓርነት ኤርትራን ህዝባዊ ግንባር ሓርነት ኤርትራን ዝተደለቡ ቅየማትን (values) መሰረት
                  ፖሊቲካዊ ክኾኑ ይህቅኑ። እቲ ካልኣይ ዝምባለ ግን መንግስቲ ኣብ ስደት ኣብ ብህዝቢ ዝተሰርዔ ጉባኤ
                  ኽቐውም ይነጥፍ። ተስፋ ህዝብና እዞም ክልተ ዝምባለታት ሓቢሮም ንፕሮጀክት መንግስቲ ኤርትራ ኣብ
                  ስደት ከዐውቱ እዩ።
                </p>

                <p className="text-justify">
                  እቶም ንህዝቢ ከነዕውት (empower) ዝብሉ ክቡራት ኤርትራውያን በዞም ኣብ ታሕቲ ተጠቒሶም ዘለዉ
                  ኣገባባት ይጥቀሙ፥
                </p>

                <p className="text-justify">ሞንጎንያታት &nbsp;&nbsp;(Sponors)</p>

                <p className="text-justify">
                  ኣሳለጥቲ &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(Facilitators)
                </p>

                <p className="text-justify">
                  ሰበ-ብርኪ &nbsp;&nbsp;&nbsp;&nbsp;(Stakeholders)
                </p>

                <p className="text-justify">ሓይሊ ዕማም &nbsp;(task forces)</p>

                <p className="text-justify">
                  እቶም ህዝቢ ኤርትራ ወናኒ ባዕለ-ስልጣኑ (self-empowerment) እዩ ዝብሉ ድማ በዞም ኣብ
                  ታሕቲ ስዒቦም ዘለዉ መትከላት ይምርሑ፥
                </p>

                <ul>
                  <li>ወነንቲ ህዝባዊ ሕጋውነት (legitimacy)</li>
                  <li>ሓላፍነት ምዝገባ ኤርትራውያን ኣብ ስደት</li>
                  <li>ሓለፍቲ ደሞክራስያዊ ምርጻ ኣሰናዳኢት ሽማግለ</li>
                  <li>
                    ሕጋዊ መሪሕነት ብዓድታትን ከተማታትን ወረዳታትን ወረጃታትን ስለ ዝቐውም ባዕሉ ጉባኤ ከሰናድእ
                    ይኽእል እዩ
                  </li>
                </ul>

                <p className="text-justify">
                  ኣብ ግዜ ብረታዊ ቃልሲ መሪሕነታት ተጋድሎ ሓርነት ኤርትራን ህዝባዊ ግንባር ሓርነት ኤርትራን
                  ንመትከል መሬት ናይ ዓዲ ካብ ዋናታቱ መንጢሎም ደየስዎ። ህዝቢ ኤርትራ ሓርነቱ ዝማልኣሉ መሬቱ ምስ
                  ተመልሰሉ እዩ። ግዝይኡ ድማ ሕጂ እዩ። ብተመሳሳሊ ህዝቢ ኤርትራ ብዘይሕጋውያን ገዛእቲ ኤርትራ፡
                  ወረጃታቱ ተነጢቑ እዩ። ህዝቢ ኤርትራ ሓርነቱ ዝረጋገጽ ወርጃታቱ ኣብ ንቡር ቦትኦም ምስ ዝምለሱ
                  እዩ። ግዝይኡ ድማ ሕጂ እዩ።
                </p>

                <p className="text-justify">ዴሞክርስያዊ ምርጻ መንግስቲ ኣብ ስደት</p>

                <p className="text-justify">ቀዳማይ ስጉምቲ</p>

                <p className="text-justify">
                  ምዝገባ ኤርትራውያን ኣብ ስደት ኣብ ዳታበይዝ ይኸውን። ደቂ ዓድታት ኣብ ናይ ብሕቲ ገጸ-መርበብ
                  ይምዝገቡ፡ በብጸፍሑ ድማ ኣብ ወረደታቶምን ወረጃታቶምን ይጥርነፉ።
                </p>

                <p className="text-justify">ካልኣይ ስጉምቲ</p>

                <p className="text-justify">
                  ኤርትራ ብዓድታትን ወረዳታትን ወረጃታትን ዝቐወመት ሃገር ስለ ዝዀነት፡ ስርዓት ምርጻን ሓላፍነት
                  ጻውዒት ጉባኤ ንህንጸት መንግስቲ ኣብ ስደትን ኣብ ኢድ መራሕቲ ወረጃታት ይወድቕ።
                </p>

                <p className="text-justify">
                  ሕጹያት ካብ ዓዶምን ከተማኦምን ወረዳኦምን ወረጃኦምን ይምረጹ።&nbsp;
                </p>

                <p className="text-justify">
                  ምዝገባ ምስ ተማልኤ፡ ህዝቢ ኤርትራ ኣብ ዳት ቤዝ ፖለቲካዊ ሓይሊ ከም ምዃኑ መጠን፡ መንግስቲ ኣብ
                  ስደት ብምቛም መሰሉ የረጋግጽ። ቀጺሉ ምቛም ኣሳናዳኢት ሽማግለ የሰላስ።
                </p>

                <p className="text-justify">
                  ኣባልነት ምስ ተመዘግበ ዜግነት ድማ ብውልቃዊ ተሴራ ይረጋገጽ።
                </p>

                <p className="text-justify">
                  ምርጻ ብቐጥታ ንውልቀ ሰባት እምበር ንውድባትን ሰልፍታትን ኣይኰነን። ውድባትን ሰልፍታትን በርጌሳዊ
                  ማሕበራትን ናቶም ጉባኤታት ኣለዎም።
                </p>

                <p className="text-justify">
                  መሰል ሕጹይነት ኣብ መንግስቲ ስደት ንኣባላት ባይቶን ይኣክልን ነጸላ ውድባትን በርጌሳዊ ማሕበራትን
                  መዓጾ ክፉት እዩ፣ ኣውራ ንሃገር ኣድለይቲ ስለ ዝዀኑ።
                </p>

                <p className="text-justify">
                  ስም፡
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ቖፎ
                  ሓሳባት ንመንግስቲ ኣብ ስደት
                </p>

                <p className="text-justify">
                  ዕላማ፡
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;መጽናዕቲ
                  ምስንዳእን ምዝርጋሕን ምቕራጽን ኰይኑ፡ ህዝብና ንዝጠልቦም ጉዳያት &nbsp;&nbsp;ትግባረ ምኽሪ
                  ብጽሑፍ ምብርካት እዩ።
                </p>

                <p className="text-justify">
                  ንሕና መን ኢና? ንሕና ንውድባትን ሰልፍታትን ዘይንውክል ወለንታውያን ኤርትራውያን ኢና።
                </p>

                <p className="text-justify">
                  ግደ ክኢላታትን ምኩራትን ንህንጸት ኤርትራዊ መንግስቲ ኣብ ስደት
                </p>

                <p className="text-justify">
                  ብምሉእ ትሕትና ቖፎ ሓሳባት ንመንግስቲ ኣብ ስደት፡ ንክኢላታትን ምኩራትን ኣብ ምዕዋት ምሳና
                  ክማኸሩን ክነጥፉን ይዕድም። ዝለ’ዚ ንክኢላታትን ሰበ ተሞክሮ ዝኸውን ኣካል ክቐውም እዩ።
                </p>

                <p className="text-justify">
                  መሕሰቢ፡ ኣብ ክንዲ ኣ(ውራጃ)ታት ወረጃ መሪጽና ኣሎና
                </p>

                <p className="text-justify">ንመንግስቲ ኣብ ስደት ንቃለስ</p>

                <p className="text-justify">ዘልኣለማዊ ዝኽሪ ንስዉኣትና</p>

                <p className="text-justify">
                  ስቶክሆልም&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  14 ታሕሳስ 2021
                </p>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </Fragment>
  )
}
