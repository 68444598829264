import { initializeApp } from "firebase/app"
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut
} from "firebase/auth"
import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  addDoc,
  Timestamp,
  orderBy
} from "firebase/firestore"
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage"
import { nanoid } from "nanoid"

const firebaseConfig = {
  apiKey: "AIzaSyCjBm9EHXGTcbQVckkdZmTGdmGDQgvnq7Y",
  authDomain: "sett-c2979.firebaseapp.com",
  projectId: "sett-c2979",
  storageBucket: "sett-c2979.appspot.com",
  messagingSenderId: "1055552487301",
  appId: "1:1055552487301:web:825e88312d30a2ff4f4eec",
  measurementId: "G-840QN6NQPG"
}

const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
const db = getFirestore(app)
const storage = getStorage(app)
const googleProvider = new GoogleAuthProvider()

const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider)
    const user = res.user
    const q = query(collection(db, "users"), where("uid", "==", user.uid))
    const docs = await getDocs(q)
    if (docs.docs.length === 0) {
      let frRes = await addDoc(collection(db, "users"), {
        uid: user.uid,
        displayName: user.displayName,
        authProvider: "google",
        email: user.email
      })
      console.log(frRes, "***no user found***")
    }
  } catch (err) {
    console.error(err)
    alert(err.message)
  }
}
const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password)
  } catch (err) {
    console.error(err)
    alert(err.message)
  }
}
const registerWithEmailAndPassword = async (name, email, password) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password)
    const user = res.user
    await addDoc(collection(db, "users"), {
      uid: user.uid,
      displayName: name,
      authProvider: "local",
      email
    })
  } catch (err) {
    console.error(err)
    // alert(err.message)
  }
}
const sendPasswordReset = async email => {
  try {
    await sendPasswordResetEmail(auth, email)
    alert("Password reset link sent!")
  } catch (err) {
    console.error(err)
    alert(err.message)
  }
}
const logout = () => {
  signOut(auth)
}

// Articles
const addArticle = async article => {
  try {
    const newArticle = await addDoc(collection(db, "articles"), {
      ...article,
      postedDate: Timestamp.now()
    })
  } catch (error) {
    console.log("Firebase error: ", error)
  }
}

const getUser = async uid => {
  console.log(uid)
  try {
    const q = query(collection(db, "users"), where("uid", "==", uid))
    const docs = await getDocs(q)
    docs.forEach(doc => {
      console.log(doc.data(), "***user***")
    })
  } catch (error) {
    console.log(error)
  }
}

const getArticles = async () => {
  try {
    const q = query(collection(db, "articles"))
    const querySnapshot = await getDocs(q)
    const values = []
    querySnapshot.forEach(doc => {
      values.push(doc.data())
    })
    return values
  } catch (error) {
    console.log(error)
  }
}

const getDocuments = async type => {
  try {
    const q = query(collection(db, "documents"), where("type", "==", type))
    const querySnapshot = await getDocs(q)
    const values = []
    querySnapshot.forEach(doc => {
      values.push(doc.data())
    })
    return values
  } catch (error) {
    console.log(error)
  }
}

// documents
const addDocument = async document => {
  console.log(document)
  try {
    const newDocument = await addDoc(collection(db, "documents"), {
      ...document,
      postedDate: Timestamp.now()
    })
    return newDocument
  } catch (error) {
    console.log(error)
    throw error
  }
}

const uploadDocumentFile = async file => {
  let nameArr = file.name.split(".")
  const storageDocuments = ref(
    storage,
    `documents/${nanoid()}.${nameArr[nameArr.length - 1]}`
  )
  let res = await uploadBytes(storageDocuments, file)

  let url = await getDownloadURL(res.ref)
  return url
}

export {
  auth,
  db,
  signInWithGoogle,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
  addArticle,
  getArticles,
  getUser,
  uploadDocumentFile,
  addDocument,
  getDocuments
}
