import React, { Fragment } from "react"

export default function Representatives() {
  return (
    <Fragment>
      <div>
        <h1 className="display-4 my-3 font-weight-bold">
          Werejatat Representatives
        </h1>
        <div>
          <div>
            <p>
              <strong>
                WEREJATAT – Werejatat are effectively nine regional states of
                Eritrea namely:
              </strong>
            </p>

            <ol className="list-disc">
              <li>Akele-Guzay (ኣከለጉዛይ, أكيليجوزاي)</li>
              <li>Barka (ባርካ, بركاء)</li>
              <li>Denkalia-Afar (ደንካሊያ, دينكاليا)</li>
              <li>Gash-Setit (ጋሽን ሰቲትን, جاش سيتيت)</li>
              <li>Hamassien (ሓማሴን, حماسين)</li>
              <li>Sahel (ሳሕል, الساحل)</li>
              <li>Semhar (ሰምሃር, سيمهار)</li>
              <li>Senhit (ሰንሒት, ألف يوم ت)</li>
              <li>Seraye (ሰራየ, سيراي)</li>
            </ol>
          </div>
        </div>
      </div>
    </Fragment>
  )
}
