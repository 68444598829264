import {
  Button,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Snackbar
} from "@material-ui/core"
import React, { Fragment, useState } from "react"
import { addDocument, auth, uploadDocumentFile } from "utils/firebase"
import { useAuthState } from "react-firebase-hooks/auth"
import { BarLoader } from "react-spinners"
import { useHistory } from "react-router-dom"
import { Alert } from "@material-ui/lab"

export default function UploadDocument() {
  const history = useHistory()
  const [user, loading, error] = useAuthState(auth)
  const [name, setName] = useState("")
  const [type, setType] = useState("")
  const [files, setFiles] = useState([])
  const [status, setStatus] = useState("idle")
  const [errorMessage, setErrorMessage] = useState("")

  const onSubmit = async () => {
    if (!user) {
      history.push({
        pathname: "/login",
        search: `?return_url=${history.location.pathname}`
      })
      return
    }
    if (files?.length <= 0 || !name || !type) {
      setStatus("rejected")
      setErrorMessage("Please fill in all the inputs and try again.")
      return
    }

    setStatus("loading")
    let documentURL = await uploadDocumentFile(files[0]).catch(err => {
      setStatus("rejected")
      setErrorMessage("Can not upload document now try later.")
    })

    let uploadedDocument = await addDocument({
      documentURL,
      name,
      type,
      uploadedBy: user.email
    }).catch(err => {
      setStatus("rejected")
      setErrorMessage("Can not upload document now try later.")
    })

    if (uploadedDocument) {
      setStatus("resolved")
    }
  }

  return (
    <Fragment>
      <div className="">
        <h1 className="display-4 my-3 font-weight-bold">Upload Document</h1>

        <div className="p-1">
          {status === "rejected" && (
            <div className="text-red-600 text-lg text-center my-2">
              {errorMessage}
            </div>
          )}
          <form className="max-w-2xl mx-auto">
            <FormControl className="w-full lg:w-1/2 pr-2 lg:pr-0">
              <InputLabel htmlFor="document_name">Document Name</InputLabel>
              <Input
                value={name}
                onChange={e => {
                  setName(e.target.value)
                }}
                id="document_name"
                aria-describedby="my-helper-text"
              />
              <FormHelperText id="my-helper-text">
                The text you want displayed with the document
              </FormHelperText>
            </FormControl>
            <FormControl className="w-full lg:w-1/2">
              <InputLabel htmlFor="document_type">Document Type</InputLabel>
              <Select
                value={type}
                onChange={e => {
                  setType(e.target.value)
                }}
                inputProps={{
                  name: "document_type",
                  id: "document_type"
                }}
              >
                <MenuItem aria-label="None" value="">
                  Select Document Type
                </MenuItem>
                <MenuItem value={"ARTICLE"}>Article</MenuItem>
                <MenuItem value={"ARCHIVE"}>Archive</MenuItem>
                <MenuItem value={"CURRENT_DOCUMENT"}>Current Document</MenuItem>
                <MenuItem value={"ERITREA_GOVT_IN_EXILE"}>
                  Eritrea Gov't in Exile
                </MenuItem>
              </Select>
            </FormControl>

            <FormControl className="w-full mt-4">
              {/* <InputLabel htmlFor="document_name">Document Name</InputLabel> */}
              <Input
                type="file"
                id="document_file"
                aria-describedby="my-helper-text"
                onChange={e => {
                  console.log(e.target.files)
                  setFiles(e.target.files)
                }}
              />
              <FormHelperText id="my-helper-text">
                Upload any type of file you want.
              </FormHelperText>
            </FormControl>

            {status === "loading" && (
              <div className="h-16 flex items-center justify-center">
                <div className="d-flex align-items-center flex-column px-4">
                  <BarLoader color={"#5383ff"} loading={true} />
                </div>
              </div>
            )}
            {status !== "loading" && (
              <Button
                className="w-full mt-4"
                onClick={onSubmit}
                variant="contained"
                color="primary"
              >
                Upload Document
              </Button>
            )}
          </form>
        </div>
      </div>
      {status === "resolved" && (
        <Snackbar
          onClose={() => setStatus("idle")}
          open={status === "resolved"}
          autoHideDuration={6000}
        >
          <Alert onClose={() => setStatus("idle")} severity="success">
            {"Successfully added document"}
          </Alert>
        </Snackbar>
      )}
    </Fragment>
  )
}
