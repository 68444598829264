import React, { Fragment } from "react"

import clsx from "clsx"
import { Link } from "react-router-dom"

import { IconButton, Box } from "@material-ui/core"

import { connect } from "react-redux"

import projectLogo from "../../../assets/images/logo_circle.png"

const HeaderLogo = props => {
  const { sidebarToggle, sidebarHover } = props
  return (
    <Fragment>
      <div
        className={clsx("app-header-logo", {
          "app-header-logo-close": sidebarToggle,
          "app-header-logo-open": sidebarHover
        })}
      >
        <Box
          className="header-logo-wrapper"
          title="Society of Eritrean Think Tank"
        >
          <Link to="/" className="header-logo-wrapper-link">
            <IconButton
              color="primary"
              size="large"
              className="bg-white rounded-full p-0 overflow-hidden w-14 h-14"
            >
              <img
                className="w-16 h-16 scale-120"
                alt="Society of Eritrean Think Tank"
                src={projectLogo}
              />
            </IconButton>
          </Link>
          <Box className="header-logo-text">SETT</Box>
        </Box>
      </div>
    </Fragment>
  )
}

const mapStateToProps = state => ({
  sidebarToggle: state.ThemeOptions.sidebarToggle,
  sidebarHover: state.ThemeOptions.sidebarHover
})

export default connect(mapStateToProps)(HeaderLogo)
