import React, { Fragment, useEffect, useState } from "react"
import { useAuthState } from "react-firebase-hooks/auth"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { BarLoader } from "react-spinners"

import {
  Grid,
  Container,
  Input,
  InputLabel,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  Card,
  CardContent,
  Button,
  FormControl,
  IconButton
} from "@material-ui/core"

import MailOutlineTwoToneIcon from "@material-ui/icons/MailOutlineTwoTone"
import LockTwoToneIcon from "@material-ui/icons/LockTwoTone"

import svgImage9 from "../assets/images/illustrations/login.svg"

import { NavLink as RouterLink } from "react-router-dom"
import {
  auth,
  getUser,
  logInWithEmailAndPassword,
  signInWithGoogle
} from "utils/firebase"
import { useHistory } from "react-router-dom"

const Login = () => {
  const history = useHistory()
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [checked1, setChecked1] = React.useState(true)
  const [user, loading, error] = useAuthState(auth)
  const [status, setStatus] = useState("idle")

  const params = new URLSearchParams(history.location.search)
  const returnURL = params.get("return_url")

  const handleChange1 = event => {
    setChecked1(event.target.checked)
  }

  useEffect(() => {
    if (error) {
      setStatus("error")
    }
    if (loading) {
      setStatus("loading")
      return
    }
    setStatus("idle")
    console.log(user)
    if (user) {
      fetchUser(user.uid)
      history.push(`${returnURL || "/"}`)
    }
  }, [user, loading, error])

  const fetchUser = async uid => {
    let res = await getUser(uid)
  }

  return (
    <Fragment>
      <div className="app-wrapper min-vh-100 ">
        <div className="app-main flex-column">
          <Button
            size="large"
            variant="outlined"
            color="primary"
            component={RouterLink}
            className="btn-go-back"
            to="/"
          >
            <span className="btn-wrapper--icon">
              <FontAwesomeIcon icon={["fas", "arrow-left"]} />
            </span>
            <span className="btn-wrapper--label">Back</span>
          </Button>
          <div className="app-content p-0">
            <div className="app-content--inner d-flex align-items-center">
              <div className="flex-grow-1 w-100 d-flex align-items-center">
                <div className="bg-composed-wrapper--content py-5">
                  <Container maxWidth="lg">
                    <Grid className="flex justify-center" container spacing={5}>
                      <Grid
                        item
                        xs={12}
                        lg={7}
                        className="d-flex flex-column align-items-center"
                      >
                        <span className="w-100 text-left text-md-center pb-4">
                          <h1 className="display-3 text-xl-left text-center mb-3 font-weight-bold">
                            Login to your account
                          </h1>
                        </span>
                        <Card className="m-0 w-100 p-0 border-0">
                          <div className="card-header d-block p-3 mx-2 mb-0 mt-2 rounded border-0">
                            <div className="text-center mb-3">
                              <span>Sign in with</span>
                            </div>
                            <div className="text-center">
                              <Button
                                onClick={signInWithGoogle}
                                variant="outlined"
                                className="mr-2 text-google px-4"
                              >
                                <span className="btn-wrapper--icon">
                                  <FontAwesomeIcon icon={["fab", "google"]} />
                                </span>
                                <span className="btn-wrapper--label">
                                  Google
                                </span>
                              </Button>
                            </div>
                          </div>
                          <CardContent className="p-3">
                            <div className="text-center text-black-50 mb-3">
                              <span>Or sign in with credentials</span>
                            </div>
                            {loading === "error" && (
                              <div className="text-center text-red-500 mb-3">
                                <span>{"Server error. Try again."}</span>
                              </div>
                            )}
                            <form className="px-5">
                              <div className="mb-3">
                                <FormControl className="w-100">
                                  <InputLabel htmlFor="input-with-icon-adornment">
                                    Email address
                                  </InputLabel>
                                  <Input
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}
                                    fullWidth
                                    id="input-with-icon-adornment"
                                    startAdornment={
                                      <InputAdornment position="start">
                                        <MailOutlineTwoToneIcon />
                                      </InputAdornment>
                                    }
                                  />
                                </FormControl>
                              </div>
                              <div className="mb-3">
                                <FormControl className="w-100">
                                  <InputLabel htmlFor="standard-adornment-password">
                                    Password
                                  </InputLabel>
                                  <Input
                                    value={password}
                                    onChange={e => setPassword(e.target.value)}
                                    id="standard-adornment-password"
                                    fullWidth
                                    type="password"
                                    startAdornment={
                                      <InputAdornment position="start">
                                        <LockTwoToneIcon />
                                      </InputAdornment>
                                    }
                                  />
                                </FormControl>
                              </div>
                              {/* <div className="w-100">
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={checked1}
                                      onChange={handleChange1}
                                      value="checked1"
                                      color="primary"
                                    />
                                  }
                                  label="Remember me"
                                />
                              </div> */}
                              <div className="text-center">
                                {status === "loading" && (
                                  <div className="h-16 flex items-center justify-center">
                                    <div className="d-flex align-items-center flex-column px-4">
                                      <BarLoader
                                        color={"#5383ff"}
                                        loading={true}
                                      />
                                    </div>
                                  </div>
                                )}
                                {status !== "loading" && (
                                  <Button
                                    onClick={() => {
                                      setStatus("loading")
                                      logInWithEmailAndPassword(email, password)
                                    }}
                                    color="primary"
                                    variant="contained"
                                    size="large"
                                    className="my-2"
                                  >
                                    Sign in
                                  </Button>
                                )}
                              </div>
                            </form>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  </Container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}
export default Login
