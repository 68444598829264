import React, { Fragment, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { BarLoader } from "react-spinners"
import { getDocuments } from "utils/firebase"

export default function EritreanGovExile() {
  const [status, setStatus] = useState("idle")
  const [documents, setDocuments] = useState([])

  useEffect(() => {
    ;(async () => {
      setStatus("loading")
      let res = await getDocuments("ERITREA_GOVT_IN_EXILE").catch(err => {
        setStatus("rejected")
      })
      if (res) {
        setStatus("resolved")
        setDocuments(res)
      }
    })()
  }, [])
  return (
    <Fragment>
      <div className="custom_background">
        <h1 className="display-4 my-3 font-weight-bold">
          Eritrea Gov't in Exile
        </h1>
        <div>
          {status === "loading" && (
            <div className="h-16 flex items-center justify-center">
              <div className="d-flex align-items-center flex-column px-4">
                <BarLoader color={"#5383ff"} loading={true} />
              </div>
            </div>
          )}
          {status === "resolved" &&
            documents.map(doc => {
              return (
                <div className="flex items-center mt-4 mb-4">
                  <span className="inline-block mr-4">{doc.name}</span>
                  <Link
                    className="flex items-center text-white bg-primary py-2 px-4 font-extrabold text-lg "
                    to={{ pathname: doc.documentURL }}
                    target="_blank"
                    style={{ borderRadius: "5rem" }}
                  >
                    <span className="inline-block">View</span>
                    <span className="inline-block ml-2">
                      {/* <RefWebLink className="font-size-xl" /> */}
                    </span>
                  </Link>
                </div>
              )
            })}
        </div>
      </div>
    </Fragment>
  )
}
