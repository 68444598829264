import React, { Fragment } from "react"

export default function RapidEmergencyUnit() {
  return (
    <Fragment>
      <div>
        <h1 className="display-4 my-3 font-weight-bold">
          ERITREA RAPID EMERGENCY UNIT – EREU
        </h1>
        <div>
          <p className="text-justify">
            This unit will be tasked with emergency requirements needed by
            Eritrean in exile as well as inside Eritrean in wake of the fall of
            the regime. Such dual tasks will be mandated by this Unit. In the
            immediate needs of Eritrean in exile will be met directly as well as
            provide advocacy on their safety and wellbeing with the host
            countries. Their suffering and negligence at the host countries is
            too glaring. Much needed assistance will be raised to alleviate to
            their hardship. Furthermore, the Unit will collate all the life
            necessity shortages and the needs of Eritrean people in the wake of
            the fall of the government. To this end full market provision
            requirement management will be instituted.
          </p>
        </div>
      </div>
    </Fragment>
  )
}
